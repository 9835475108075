import { Step as StepProto } from '@agentero/grpc-clients/grpc/shared/form';
import { InvalidDataReceivedError } from '@agentero/service-errors';

import { RiskState } from '../RaterSchema';
import {
	BasicStep,
	BasicStepState,
	getBasicStepDestinations,
	getBasicStepStateFromDestinations,
	parseBasicStep
} from './BasicStep';
import {
	CarrierStep,
	CarrierStepState,
	getCarrierStepStateFromDestinations,
	getCarriersStepDestinations,
	parseCarrierStep
} from './CarrierStep';
import {
	ListStep,
	ListStepState,
	getListStepDestinations,
	getListStepStateFromDestinations,
	parseListStep
} from './ListStep';
import { FieldDestination } from './step/section/field/FieldDestination';

export enum StepType {
	Basic,
	List,
	CarrierQuestions,
	Summary,
	Address
}

export type IStep = {
	id: string;
	name: string;
	title?: string;
	description?: string;
};

export type Step = BasicStep | ListStep | CarrierStep | SummaryStep | AddressStep;
export type RiskStepState = BasicStepState | CarrierStepState | ListStepState[];

export type SummaryStep = IStep & {
	type: StepType.Summary;
};

export type AddressStep = IStep & {
	type: StepType.Address;
};

export const parseStep = (step: StepProto.AsObject): Step => {
	if (step.basicStep) {
		return parseBasicStep(step.basicStep);
	}

	if (step.carrierStep) {
		return parseCarrierStep(step.carrierStep);
	}

	if (step.listStep) {
		return parseListStep(step.listStep);
	}

	throw new InvalidDataReceivedError({
		messageError: 'Invalid rater step, type from "oneof" is not defined',
		logMetadata: {}
	});
};

export const getStepDestinations = (step: Step, values: RiskState) =>
	stepRiskMapping[step.type](step, values[step.id]);

const stepRiskMapping: {
	[key in StepType]: (step: Step, values: RiskStepState) => FieldDestination;
} = {
	[StepType.Basic]: (step, values) =>
		getBasicStepDestinations(step as BasicStep, values as BasicStepState),
	[StepType.CarrierQuestions]: (step, values) =>
		getCarriersStepDestinations(step as CarrierStep, values as CarrierStepState),
	[StepType.List]: (step, values) =>
		getListStepDestinations(step as ListStep, values as ListStepState[]),
	[StepType.Summary]: () => ({}),
	[StepType.Address]: () => ({})
};

export const getStepStateFromDestinations = (step: Step, values: Record<string, string>) =>
	destinationsStepMapping[step.type](step, values);

const destinationsStepMapping: {
	[key in StepType]: (step: Step, values: Record<string, string>) => RiskStepState | undefined;
} = {
	[StepType.Basic]: (step, values) => getBasicStepStateFromDestinations(step as BasicStep, values),
	[StepType.CarrierQuestions]: (step, values) =>
		getCarrierStepStateFromDestinations(step as CarrierStep, values),
	[StepType.List]: (step, values) => getListStepStateFromDestinations(step as ListStep, values),
	[StepType.Summary]: () => ({}),
	[StepType.Address]: () => ({})
};
