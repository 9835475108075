import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { parseList } from '../../../shared/parseList';
import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { parseValidation } from './field/shared/Validation';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';
import { Option, parseOption } from './shared/Option';

export type MultipleChoiceInput = BaseField & {
	type: FieldType.MultipleChoice;
	options: Option[];
	defaultValue?: string[];
};

export const parseMultipleChoiceField = (field: FieldProto.AsObject): MultipleChoiceInput => ({
	type: FieldType.MultipleChoice,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	options: parseList('Options', parseOption, field.multiChoice?.optionsList),
	//@ts-ignore
	defaultValue: field.defaultValue.split(','),
	validation: parseValidation(field.validation),
	prefillFromField: field.prefillFromField
});

export const getMultipleChoiceStateFromDestinations: FieldStateMapping<MultipleChoiceInput> = (
	field,
	values,
	listsIndex
) => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const multipleChoiceValue = valueToString(values[destination]);

	if (!multipleChoiceValue) return {};

	return { [field.name]: multipleChoiceValue.split(',') };
};
