import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { FieldDestination } from './field/FieldDestination';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';
import { cleanNullsAndUndefinedDestinations } from './shared/cleanUndefinedDestinations';

export const VIN_INPUT_NUMBER_PROP = 'number';
export const VIN_INPUT_MAKE_PROP = 'make';
export const VIN_INPUT_MODEL_PROP = 'model';
export const VIN_INPUT_YEAR_PROP = 'year';

export type VINInput = Partial<BaseField> & {
	name: string;
	type: FieldType.VIN;
	complete: boolean;
	destinationNumber: string;
	destinationYear: string;
	destinationMake: string;
	destinationModel: string;
};

export type VINState = {
	[VIN_INPUT_NUMBER_PROP]?: string;
	[VIN_INPUT_MAKE_PROP]: string;
	[VIN_INPUT_MODEL_PROP]: string;
	[VIN_INPUT_YEAR_PROP]: string;
};

export const isValidVIN = (vin: VINState) => vin.make && vin.model && vin.year;
export const isCompletedVIN = (vin: VINState) => vin.number && vin.make && vin.model && vin.year;

export const parseVINField = (field: FieldProto.AsObject): VINInput => ({
	type: FieldType.VIN,
	name: field.name,
	complete: field.vin!.completed,
	destinationNumber: field.vin!.destinationNumber,
	destinationYear: field.vin!.destinationYear,
	destinationMake: field.vin!.destinationMake,
	destinationModel: field.vin!.destinationModel
});

export const getVINDestination = (
	field: VINInput,
	value?: VINState,
	listsIndex: string[] = []
): FieldDestination => {
	const numberDestination = fieldDestinationIndexReplace(field.destinationNumber, listsIndex);
	const yearDestination = fieldDestinationIndexReplace(field.destinationYear, listsIndex);
	const makeDestination = fieldDestinationIndexReplace(field.destinationMake, listsIndex);
	const modelDestination = fieldDestinationIndexReplace(field.destinationModel, listsIndex);

	return {
		[numberDestination]: valueToString(value?.number),
		[makeDestination]: valueToString(value?.make),
		[modelDestination]: valueToString(value?.model),
		[yearDestination]: valueToString(value?.year)
	};
};

export const getVINStateFromDestinations: FieldStateMapping<VINInput> = (
	field,
	values,
	listsIndex
) => {
	const numberDestination = fieldDestinationIndexReplace(field.destinationNumber, listsIndex);
	const yearDestination = fieldDestinationIndexReplace(field.destinationYear, listsIndex);
	const makeDestination = fieldDestinationIndexReplace(field.destinationMake, listsIndex);
	const modelDestination = fieldDestinationIndexReplace(field.destinationModel, listsIndex);

	const numberValue = values[numberDestination];
	const yearValue = values[yearDestination];
	const makeValue = values[makeDestination];
	const modelValue = values[modelDestination];

	if (
		(field.complete && (!numberValue || !yearValue || !makeValue || !modelValue)) ||
		(!numberValue && !yearValue && !makeValue && !modelValue)
	) {
		return {};
	}

	return {
		[field.name]: cleanNullsAndUndefinedDestinations<VINState>({
			[VIN_INPUT_NUMBER_PROP]: numberValue,
			[VIN_INPUT_YEAR_PROP]: yearValue,
			[VIN_INPUT_MAKE_PROP]: makeValue,
			[VIN_INPUT_MODEL_PROP]: modelValue
		})
	};
};

export const getVINDestinationName = (field: VINInput) => [
	field.destinationNumber,
	field.destinationYear,
	field.destinationMake,
	field.destinationModel
];
