import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { parseList } from '../../../shared/parseList';
import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldType } from './field/FieldType';
import { Validation, parseValidation } from './field/shared/Validation';
import { Option, parseOption } from './shared/Option';

export type DropdownInput = BaseField & {
	type: FieldType.Dropdown;
	options: Option[];
	defaultValue?: string;
	placeholder?: string;
	validation?: Validation;
};

export const parseDropdownField = (field: FieldProto.AsObject): DropdownInput => ({
	type: FieldType.Dropdown,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	validation: parseValidation(field.validation),
	description: field.description,
	options: parseList('Options', parseOption, field.dropdown?.optionsList),
	defaultValue: field.defaultValue,
	prefillFromField: field.prefillFromField
});
