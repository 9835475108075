import { Size } from '@agentero/grpc-clients/grpc/shared/form';

export enum FieldSize {
	Small,
	Medium,
	MediumLarge,
	Large
}

const sizeMapping: { [key in FieldSize]: number } = {
	[FieldSize.Small]: 3,
	[FieldSize.Medium]: 6,
	[FieldSize.MediumLarge]: 9,
	[FieldSize.Large]: 12
};

export const getMdSize = (size: FieldSize) => sizeMapping[size];

const sizeFromProtoMapping: { [key in Size]: FieldSize } = {
	[Size.SIZE_SMALL]: FieldSize.Small,
	[Size.SIZE_MEDIUM]: FieldSize.Medium,
	[Size.SIZE_LARGE]: FieldSize.Large,
	[Size.SIZE_MEDIUM_LARGE]: FieldSize.MediumLarge,
	[Size.SIZE_UNSPECIFIED]: FieldSize.Medium
};

export const parseFieldSize = (size: Size): FieldSize => sizeFromProtoMapping[size];
