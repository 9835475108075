import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { Field, parseField } from './Field';
import { DependsOn, parseDependsOn } from './field/DependsOn';

export type NestedField = {
	field: Field;
	dependsOn: DependsOn;
};

export const parseNestedField = (field: FieldProto.AsObject): NestedField => ({
	field: parseField(field),
	dependsOn: parseDependsOn(field.dependsOn!)
});

export const addNestedFields = (field: Field, nestedFields: NestedField[]): Field => {
	if (nestedFields && nestedFields.length > 0) {
		field.nestedFields = nestedFields;
	}

	return field;
};
