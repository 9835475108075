import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';
import { getDateFormatFromString, parseDateStringToUTC } from '@agentero/utils';

import { BaseField } from './Field';
import { FieldDestination } from './field/FieldDestination';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';

export type DateInput = BaseField & {
	type: FieldType.Date;
	placeholder: string;
	minAge?: number;
	maxAge?: number;
	maxDate?: string;
	minDate?: string;
};

export const parseDateField = (field: FieldProto.AsObject): DateInput => {
	return {
		type: FieldType.Date,
		name: field.name,
		label: field.label,
		destination: field.destination,
		size: parseFieldSize(field.size),
		required: field.required,
		description: field.description,
		placeholder: field.date?.placeholder as string,
		defaultValue: field.defaultValue,
		maxAge: field.date?.maxAge ? field.date?.maxAge : undefined,
		minAge: field.date?.minAge ? field.date?.minAge : undefined,
		maxDate: field.date?.maxDate ? field.date?.maxDate : undefined,
		minDate: field.date?.minDate ? field.date?.minDate : undefined,
		prefillFromField: field.prefillFromField
	};
};

export const getDateDestination = (
	field: DateInput,
	value?: string,
	listsIndex: string[] = []
): FieldDestination => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);

	if (value === undefined || value === null) return { [destination]: null };

	return {
		[destination]: (parseDateStringToUTC(value) / 1000).toString()
	};
};

export const getDateStateFromDestinations: FieldStateMapping<DateInput> = (
	field,
	values,
	listsIndex
) => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const dateValue = valueToString(values[destination]);

	if (!dateValue) return {}; //TODO: TEST

	//@ts-ignore
	if (!isNaN(dateValue)) {
		return { [field.name]: getDateFormatFromString(Number(dateValue)) };
	}

	return { [field.name]: dateValue };
};
