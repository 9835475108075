import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldType } from './field/FieldType';

export type TextAreaInput = BaseField & {
	type: FieldType.TextArea;
	placeholder?: string;
};

export const parseTextAreaField = (field: FieldProto.AsObject): TextAreaInput => ({
	type: FieldType.TextArea,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	placeholder: field.textArea?.placeholder,
	defaultValue: field.defaultValue,
	prefillFromField: field.prefillFromField
});
