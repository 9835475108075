import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { FieldType } from './field/FieldType';

export enum AlertType {
	Info,
	Warning
}

export type AlertInput = Partial<BaseField> & {
	name: string;
	type: FieldType.Alert;
	text: string;
	alertType: AlertType;
};

export const parseAlertField = (field: FieldProto.AsObject): AlertInput => ({
	name: '',
	type: FieldType.Alert,
	text: field.alert!.text,
	alertType: parseAlertType(field.alert!.type)
});

const alertTypeMapping = {
	[FieldProto.Alert.alertType.INFO]: AlertType.Info,
	[FieldProto.Alert.alertType.WARNING]: AlertType.Warning
};

export const parseAlertType = (alertType: FieldProto.Alert.alertType): AlertType => {
	return alertTypeMapping[alertType];
};
