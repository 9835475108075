import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { parseValidation } from './field/shared/Validation';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';

export type CheckboxInput = BaseField & {
	type: FieldType.Checkbox;
};

export const parseCheckboxField = (field: FieldProto.AsObject): CheckboxInput => ({
	type: FieldType.Checkbox,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	validation: parseValidation(field.validation),
	prefillFromField: field.prefillFromField
});

export const getCheckboxStateFromDestinations: FieldStateMapping<CheckboxInput> = (
	field,
	values,
	listsIndex
) => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const value = valueToString(values[destination]);

	if (!value || value === '') return { [field.name]: false };

	if (value === 'yes' || value === 'no') {
		if (value === 'yes') return { [field.name]: 'true' };

		return { [field.name]: 'false' };
	}

	return { [field.name]: value };
};
