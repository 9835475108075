import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { ComparisonOperator, parseComparisonOperator } from './when/ComparisonOperator';

export type When = {
	in?: string[];
	not?: string[];
	operator?: ComparisonOperator;
	field?: string;
	value?: unknown;
};

export const parseWhen = (when: FieldProto.When.AsObject): When => ({
	in: when.valueInList,
	not: when.valueNotInList,
	operator: parseComparisonOperator(when.operator),
	field: when.field?.name,
	value: parseValue(when.values ?? when.value)
});

//TODO: handle every possible Value case
const parseValue = (
	values: FieldProto.When.Values.AsObject | string | undefined
): string[] | string | undefined => {
	if (!values) return;
	if (typeof values === 'string') return values;
	return values.valuesList;
};
