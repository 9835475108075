import { AmountInput, getAmountStateFromDestinations } from '../AmountInput';
import { CheckboxInput, getCheckboxStateFromDestinations } from '../CheckboxInput';
import { ChoiceInput, getChoiceStateFromDestinations } from '../ChoiceInput';
import { DateInput, getDateStateFromDestinations } from '../DateInput';
import { Field } from '../Field';
import {
	MultipleChoiceInput,
	getMultipleChoiceStateFromDestinations
} from '../MultipleChoiceInput';
import { NumberInput, getNumberStateFromDestinations } from '../NumberInput';
import { VINInput, VINState, getVINStateFromDestinations } from '../VINInput';
import {
	YearMonthInput,
	YearMonthState,
	getYearMonthStateFromDestinations
} from '../YearMonthInput';
import { FieldType } from './FieldType';
import { valueToString } from './FieldValue';
import { fieldDestinationIndexReplace } from './shared/fieldDestinationIndexReplace';

export type FieldState = Record<string, FieldStateValue>;
export type FieldStateValue = string[] | string | number | boolean | YearMonthState | VINState;

export type FieldStateMapping<T extends Field> = (
	field: T,
	values: Record<string, string>,
	listsIndex?: string[]
) => FieldState;

export const getDefaultState = (
	field: Field,
	values: Record<string, string>,
	listsIndex: string[] = []
): FieldState => {
	if (!field.destination)
		throw new Error(
			`Field of type ${field.type} can't be generated by getDefaultDestination, this field doesn't contain destination, use custom destination generator`
		);

	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const resultValue = valueToString(values[destination]);

	return resultValue ? { [field.name]: resultValue } : {};
};

export const fieldStateMapping: {
	[key in FieldType]: FieldStateMapping<Field>;
} = {
	[FieldType.Text]: getDefaultState,
	[FieldType.TextArea]: getDefaultState,
	[FieldType.Date]: (field, values, listsIndex) =>
		getDateStateFromDestinations(field as DateInput, values, listsIndex),
	[FieldType.Amount]: (field, values, listsIndex) =>
		getAmountStateFromDestinations(field as AmountInput, values, listsIndex),
	[FieldType.Dropdown]: getDefaultState,
	[FieldType.Choice]: (field, values, listsIndex) =>
		getChoiceStateFromDestinations(field as ChoiceInput, values, listsIndex),
	[FieldType.Email]: getDefaultState,
	[FieldType.Number]: (field, values, listsIndex) =>
		getNumberStateFromDestinations(field as NumberInput, values, listsIndex),
	[FieldType.Search]: getDefaultState,
	[FieldType.VIN]: (field, values, listsIndex) =>
		getVINStateFromDestinations(field as VINInput, values, listsIndex),
	[FieldType.YearMonth]: (field, values, listsIndex) =>
		getYearMonthStateFromDestinations(field as YearMonthInput, values, listsIndex),
	[FieldType.Alert]: () => ({}),
	[FieldType.PhoneNumber]: getDefaultState,
	[FieldType.Checkbox]: (field, values, listsIndex) =>
		getCheckboxStateFromDestinations(field as CheckboxInput, values, listsIndex),
	[FieldType.Signature]: getDefaultState,
	[FieldType.Pdf]: () => ({}),
	[FieldType.Video]: () => ({}),
	[FieldType.ScrollViewer]: () => ({}),
	[FieldType.MultipleChoice]: (field, values, listsIndex) =>
		getMultipleChoiceStateFromDestinations(field as MultipleChoiceInput, values, listsIndex)
};

export const hasValue = (value: FieldStateValue) => value === 0 || Boolean(value);
