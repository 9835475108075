import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldType } from './field/FieldType';

export type TextInput = BaseField & {
	type: FieldType.Text;
	placeholder?: string;
};

export const parseTextField = (field: FieldProto.AsObject): TextInput => ({
	type: FieldType.Text,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	placeholder: field.text?.placeholder,
	defaultValue: field.defaultValue,
	prefillFromField: field.prefillFromField
});
