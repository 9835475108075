const isObject = (item: unknown) => {
	return item && typeof item === 'object' && !Array.isArray(item);
};

export const deepMerge = <
	T extends { [key: string]: unknown },
	U extends { [key: string]: unknown }
>(
	target: T,
	source: U
): T & U => {
	const output = { ...target } as T & U;
	if (!target) {
		return source as T & U;
	} else if (!source) {
		return target as T & U;
	}

	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach(key => {
			if (isObject(source[key])) {
				if (!(key in target)) Object.assign(output, { [key]: source[key] });
				//@ts-ignore
				else output[key] = deepMerge(target[key], source[key]);
			} else {
				Object.assign(output, {
					[key]: source[key] || source[key] === false ? source[key] : target[key]
				});
			}
		});
	}
	return output;
};

export const getMappingValue = (mapping: { [key: string]: string }, key?: string) => {
	if (mapping && key && Object.keys(mapping).includes(key)) {
		return mapping[key];
	}

	return undefined;
};

export const getValueFromPath = (obj: { [key: string]: unknown }, key: string) => {
	const keys = key.split('.');

	return keys.reduce((acc: { [key: string]: {} } | undefined, curr) => {
		if (acc && acc[curr]) {
			return acc[curr];
		}

		return undefined;
	}, obj);
};
