import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { FieldType } from './field/FieldType';

export type VideoInput = Partial<BaseField> & {
	name: string;
	type: FieldType.Video;
	youtubeId: string;
};

export const parseVideoField = (field: FieldProto.AsObject): VideoInput => ({
	name: field.name,
	type: FieldType.Video,
	youtubeId: field.video!.youtubeid
});
