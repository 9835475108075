import { FieldSection, Section as SectionProto } from '@agentero/grpc-clients/grpc/shared/form';
import { InvalidDataReceivedError } from '@agentero/service-errors';

import { RiskState } from '../../RaterSchema';
import {
	Field,
	getFieldsDestinations,
	getFieldsStateFromDestinations,
	parseFields
} from './section/Field';
import { FieldDestination } from './section/field/FieldDestination';
import { FieldState } from './section/field/FieldState';
import { getStringFieldMapping } from './section/field/FieldValue';

export enum SectionType {
	Single = 'single',
	List = 'list'
}

export type BasicSection = {
	type: SectionType.Single;
	name: string;
	fields: Field[];
	repeatForSubsequent: boolean;
	title?: string;
	description?: string;
};

export type ListSection = {
	type: SectionType.List;
	minElements: number;
	maxElements: number;
	name: string;
	fields: Field[];
	repeatForSubsequent: boolean;
	title?: string;
	description?: string;
};

export type Section = BasicSection | ListSection;

export type SectionState = Record<string, FieldState | FieldState[]>;

export const parseSection = (
	section: SectionProto.AsObject,
	sections?: SectionProto.AsObject[]
): Section => {
	if (section.fieldSection) {
		return {
			...section.fieldSection,
			type: SectionType.Single,
			fields: parseFields(section.fieldSection.fieldsList, sections),
			repeatForSubsequent: section.fieldSection.repeatForSubsequent
		};
	}

	if (section.listFieldSection) {
		return {
			...section.listFieldSection,
			type: SectionType.List,
			fields: parseFields(section.listFieldSection.fieldsList, sections),
			repeatForSubsequent: section.listFieldSection.repeatForSubsequent,
			maxElements: section.listFieldSection.maxElements,
			minElements: section.listFieldSection.minElements
		};
	}

	throw new InvalidDataReceivedError({
		messageError: 'Invalid section, type from "oneof" is not defined',
		logMetadata: {}
	});
};

// This function is used to make new sections signature compatible with the old one
export const getSectionList = (
	sectionsList?: FieldSection.AsObject[],
	formSectionsList?: SectionProto.AsObject[]
): SectionProto.AsObject[] => {
	if (sectionsList && sectionsList.length > 0 && formSectionsList && formSectionsList.length > 0) {
		throw new InvalidDataReceivedError({
			messageError: 'sectionsList and formSectionsList are defined, please define only one',
			logMetadata: {}
		});
	}

	if (sectionsList && sectionsList.length > 0) {
		return sectionsList.map(section => ({
			fieldSection: section
		}));
	}
	if (formSectionsList && formSectionsList.length > 0) {
		return formSectionsList;
	}

	throw new InvalidDataReceivedError({
		messageError: 'sectionsList and formSectionsList are not defined, please define one',
		logMetadata: {}
	});
};

export const getFieldFromSectionsByName = (
	sections: Section[],
	finalName: string
): Field | undefined => {
	const [sectionName, fieldName] = finalName.split('.');

	const field = sections
		.find(section => section.name === sectionName)
		?.fields.find(field => field.name === fieldName);

	return field;
};

export const getSectionsDestinations = (
	sections: Section[],
	values?: SectionState,
	listsIndex: string[] = []
): FieldDestination => {
	return sections.reduce((dictionary, section) => {
		if (section.type === SectionType.Single) {
			const fieldState = (values as Record<string, FieldState>)?.[section.name] ?? {};
			const sectionFields = getFieldsDestinations(section.fields, fieldState, listsIndex);

			return { ...dictionary, ...sectionFields };
		}
		//TODO: Refactor ListSection set in different file
		if (section.type === SectionType.List) {
			const fieldState = (values as Record<string, FieldState[]>)?.[section.name] ?? [];

			const sectionFields = fieldState.reduce((acc, fieldState, sectionIndex) => {
				const sectionFields = getFieldsDestinations(section.fields, fieldState, [
					...listsIndex,
					sectionIndex.toString()
				]);

				return { ...acc, ...sectionFields };
			}, {} as FieldDestination) as FieldDestination; //NOTE: This is a workaround to avoid the error of the reduce function in portal-ui

			return { ...dictionary, ...sectionFields };
		}

		return dictionary;
	}, {} as FieldDestination);
};

export const getSectionsStateFromDestinations = (
	sections: Section[],
	values: Record<string, string>,
	listsIndex: string[] = []
): SectionState => {
	return sections.reduce((acc, section) => {
		if (section.type === SectionType.Single) {
			const sectionFields = getFieldsStateFromDestinations(section.fields, values, listsIndex);

			if (Object.keys(sectionFields).length === 0) return acc;

			return { ...acc, [section.name]: sectionFields };
		}

		//TODO: Refactor ListSection set in different file
		if (section.type === SectionType.List) {
			const arrayFields = [...new Array(section.maxElements)]
				.map((_, sectionIndex) => {
					const sectionFields = getFieldsStateFromDestinations(section.fields, values, [
						...listsIndex,
						sectionIndex.toString()
					]);

					if (Object.keys(sectionFields).length === 0) return undefined;

					return sectionFields;
				})
				.filter(Boolean) as FieldState[];

			return { ...acc, [section.name]: arrayFields };
		}

		return acc;
	}, {} as SectionState);
};

type GetSectionDefaultValues = {
	section: Section;
	risk: RiskState;
	prefill?: SectionState;
};

export const getSectionDefaultValues = ({
	section,
	risk,
	prefill
}: GetSectionDefaultValues): FieldState =>
	section.fields?.reduce((sectionResult, field) => {
		return { ...sectionResult, [field.name]: getStringFieldMapping(field, risk, prefill) };
	}, {});
