import { parsePhoneNumber } from '@agentero/utils';

import { getValueFromPath } from '../../../../../../shared/Object';
import { RiskState } from '../../../../RaterSchema';
import { SectionState } from '../../Section';
import { Field } from '../Field';
import { FieldType } from './FieldType';

export type FieldValue = string | number | boolean | object;

export const valueToString = (value?: FieldValue) => {
	if (value === undefined || value === null) return null;

	return value.toString();
};

const getValueOrDefault = (value: string | undefined | null): string | undefined =>
	value !== undefined && value !== null ? value : undefined;

const stringFieldSpecificMapping: { [key: string]: (value: string) => string | undefined } = {
	[FieldType.PhoneNumber]: (value: string) => getValueOrDefault(parsePhoneNumber(value))
};

export const getStringFieldMapping = (
	field: Field,
	risk: RiskState,
	prefillRisk?: SectionState
) => {
	if (prefillRisk) {
		const prefillFieldValue = getValueFromPath(prefillRisk, field.name);
		if (prefillFieldValue) {
			return prefillFieldValue;
		}
	}

	if (field.prefillFromField && field.prefillFromField !== '') {
		const defaultPrefillValue = getValueFromPath(risk, field.prefillFromField);
		if (defaultPrefillValue) {
			return defaultPrefillValue;
		}
	}

	if (field.defaultValue) {
		return stringFieldSpecificMapping[field.type]
			? stringFieldSpecificMapping[field.type](field.defaultValue as string)
			: getValueOrDefault(field.defaultValue as string);
	}

	return undefined;
};
