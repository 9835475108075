import { Field as FieldProto, Section } from '@agentero/grpc-clients/grpc/shared/form';
import { InvalidDataReceivedError } from '@agentero/service-errors';

import { AlertInput, parseAlertField } from './AlertInput';
import { AmountInput, parseAmountField } from './AmountInput';
import { CheckboxInput, parseCheckboxField } from './CheckboxInput';
import { ChoiceInput, parseChoiceField } from './ChoiceInput';
import { DateInput, parseDateField } from './DateInput';
import { DropdownInput, parseDropdownField } from './DropdownInput';
import { EmailInput, parseEmailField } from './EmailInput';
import { MultipleChoiceInput, parseMultipleChoiceField } from './MultipleChoiceInput';
import { NestedField, addNestedFields, parseNestedField } from './NestedField';
import { NumberInput, parseNumberField } from './NumberInput';
import { PdfInput, parsePdfField } from './PdfInput';
import { PhoneNumberInput, parsePhoneNumberField } from './PhoneNumberInput';
import { ScrollViewer, parseScrollViewerField } from './ScrollViewer';
import { SearchInput, parseSearchField } from './SearchInput';
import { SignatureInput, parseSignature } from './SignatureInput';
import { TextAreaInput, parseTextAreaField } from './TextAreaInput';
import { TextInput, parseTextField } from './TextInput';
import { VINInput, getVINDestinationName, parseVINField } from './VINInput';
import { VideoInput, parseVideoField } from './VideoInput';
import { YearMonthInput, getYearMonthDestinationName, parseYearMonthField } from './YearMonthInput';
import { FieldDestination, fieldDestinationMapping } from './field/FieldDestination';
import { FieldSize } from './field/FieldSize';
import { FieldState, fieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { Validation } from './field/shared/Validation';

export type Field =
	| TextInput
	| DateInput
	| AmountInput
	| DropdownInput
	| ChoiceInput
	| EmailInput
	| NumberInput
	| SearchInput
	| AlertInput
	| VINInput
	| YearMonthInput
	| PhoneNumberInput
	| CheckboxInput
	| SignatureInput
	| PdfInput
	| VideoInput
	| ScrollViewer
	| TextAreaInput
	| MultipleChoiceInput;

export type BaseField = {
	name: string;
	label: string;
	required: boolean;
	destination: string;
	size: FieldSize;
	description?: string;
	nestedFields?: NestedField[];
	validation?: Validation;
	defaultValue?: string;
	readOnly?: boolean;
	prefillFromField?: string;
};

export const parseFields = (
	fields: FieldProto.AsObject[],
	sections?: Section.AsObject[]
): Field[] => {
	if (!fields) {
		throw new InvalidDataReceivedError({
			messageError: `Property fields is empty`,
			logMetadata: {}
		});
	}
	//This allFields is for nested fields in other sections in the same step
	const nestedFields = getNestedFields(fields, sections);
	return fields
		.filter(field => !field.dependsOn)
		.map(field => {
			const nested = nestedFields
				.filter(nestedField => nestedField.dependsOn?.field === field.name)
				.map(parseNestedField);

			return addNestedFields(parseField(field), nested);
		});
};

export const parseField = (field: FieldProto.AsObject): Field => {
	if (field.text) return parseTextField(field);
	if (field.textArea) return parseTextAreaField(field);
	if (field.date) return parseDateField(field);
	if (field.amount) return parseAmountField(field);
	if (field.choice) return parseChoiceField(field);
	if (field.dropdown) return parseDropdownField(field);
	if (field.email) return parseEmailField(field);
	if (field.number) return parseNumberField(field);
	if (field.search) return parseSearchField(field);
	if (field.alert) return parseAlertField(field);
	if (field.vin) return parseVINField(field);
	if (field.yearmonth) return parseYearMonthField(field);
	if (field.phoneNumber) return parsePhoneNumberField(field);
	if (field.pb_boolean) return parseCheckboxField(field);
	if (field.signature) return parseSignature(field);
	if (field.pdf) return parsePdfField(field);
	if (field.video) return parseVideoField(field);
	if (field.scrollViewer) return parseScrollViewerField(field);
	if (field.multiChoice) return parseMultipleChoiceField(field);

	throw new InvalidDataReceivedError({
		messageError: `Unknown field type of ${field.name}`,
		logMetadata: {}
	});
};

export const getReadonlyValue = (value: string[] | string, field?: Field) => {
	if (!field) {
		return;
	}

	if (field.type === FieldType.Dropdown || field.type === FieldType.Choice) {
		return getDropdownReadonlyValue(value as string, field);
	}

	if (field.type === FieldType.MultipleChoice) {
		return getArrayReadonlyValue(value as string[], field);
	}

	return value as string;
};

const getDropdownReadonlyValue = (value: string, field: DropdownInput | ChoiceInput): string => {
	return field.options.find(option => option.value === value)?.label ?? value;
};

const getArrayReadonlyValue = (value: string[], field: MultipleChoiceInput): string => {
	return field.options.filter(option => value.includes(option.value)).map(option => option.label)
		.length > 0
		? field.options
				.filter(option => value.includes(option.value))
				.map(option => option.label)
				.join(', ')
		: value.join(', ');
};

export const getFieldsDestinations = (
	fields: Field[],
	values: FieldState,
	listsIndex: string[] = []
): FieldDestination => {
	return fields.reduce((dictionary, field) => {
		const nestedFields = field.nestedFields
			? getFieldsDestinations(
					field.nestedFields.map(({ field }) => field),
					values,
					listsIndex
			  )
			: {};

		const destinations = getDestinations(field, values, listsIndex);

		const fieldDestination = field.destination!;

		// This case is for nested field with the same destination that the field.
		if (nestedFields && nestedFields[fieldDestination] === null) {
			nestedFields[fieldDestination] = destinations[fieldDestination];
		}

		return {
			...dictionary,
			...destinations,
			...nestedFields
		};
	}, {} as FieldDestination);
};

function parseAllFieldsFromSections(sections?: Section.AsObject[]): FieldProto.AsObject[] {
	return sections
		? sections.reduce((acc, section) => {
				if (section.fieldSection) {
					return [...acc, ...section.fieldSection.fieldsList];
				}
				if (section.listFieldSection) {
					return [...acc, ...section.listFieldSection.fieldsList];
				}
				return acc;
		  }, [] as FieldProto.AsObject[])
		: [];
}

const getNestedFields = (
	fields: FieldProto.AsObject[],
	sections?: Section.AsObject[]
): FieldProto.AsObject[] => {
	let allFields;
	if (sections) {
		allFields = parseAllFieldsFromSections(sections);
	}

	return allFields
		? allFields.filter(field => Boolean(field.dependsOn))
		: fields.filter(field => Boolean(field.dependsOn));
};

const getDestinations = (
	field: Field,
	values: FieldState,
	listsIndex: string[] = []
): FieldDestination => {
	const getDestination = fieldDestinationMapping[field.type];
	return getDestination(field, values[field.name], listsIndex);
};

export const hasFieldDefaultValue = (field: Field) => {
	const defaultValue = (field as { defaultValue?: string }).defaultValue;

	return defaultValue !== undefined && defaultValue !== '';
};

export const getFieldsStateFromDestinations = (
	fields: Field[],
	values: Record<string, string>,
	listsIndex: string[] = []
): FieldState => {
	return fields.reduce((acc, field) => {
		const fieldState = fieldStateMapping[field.type](field, values, listsIndex) as FieldState;

		if (field.nestedFields) {
			const nested = getFieldsStateFromDestinations(
				field.nestedFields?.map(({ field }) => field),
				values,
				listsIndex
			);

			if (Object.keys(nested).length > 0)
				return {
					...acc,
					...fieldState,
					...nested
				};
		}

		return {
			...acc,
			...fieldState
		};
	}, {});
};

const getDefaultDestinationName = (field: Field) => (field.destination ? [field.destination] : []);

const destinationNamesMapping: { [key in FieldType]: (field: Field) => string[] } = {
	[FieldType.Text]: getDefaultDestinationName,
	[FieldType.Date]: getDefaultDestinationName,
	[FieldType.Amount]: getDefaultDestinationName,
	[FieldType.Dropdown]: getDefaultDestinationName,
	[FieldType.Choice]: getDefaultDestinationName,
	[FieldType.Email]: getDefaultDestinationName,
	[FieldType.Number]: getDefaultDestinationName,
	[FieldType.Search]: getDefaultDestinationName,
	[FieldType.VIN]: field => getVINDestinationName(field as VINInput),
	[FieldType.YearMonth]: field => getYearMonthDestinationName(field as YearMonthInput),
	[FieldType.Alert]: () => [],
	[FieldType.PhoneNumber]: getDefaultDestinationName,
	[FieldType.Checkbox]: getDefaultDestinationName,
	[FieldType.Signature]: getDefaultDestinationName,
	[FieldType.Pdf]: () => [],
	[FieldType.Video]: () => [],
	[FieldType.ScrollViewer]: () => [],
	[FieldType.TextArea]: getDefaultDestinationName,
	[FieldType.MultipleChoice]: getDefaultDestinationName
};
export const getFieldDestinationNames = (field: Field): string[] => {
	const getDestinationNames = destinationNamesMapping[field.type];
	return getDestinationNames(field);
};
