export enum FieldType {
	Text,
	Date,
	Amount,
	Dropdown,
	Choice,
	Email,
	Number,
	Search,
	VIN,
	YearMonth,
	Alert,
	PhoneNumber,
	Checkbox,
	Signature,
	Pdf,
	Video,
	ScrollViewer,
	TextArea,
	MultipleChoice
}

export const readonlyFields = [
	FieldType.Alert,
	FieldType.Pdf,
	FieldType.Video,
	FieldType.ScrollViewer
];
