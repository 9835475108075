import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldType } from './field/FieldType';

export type EmailInput = BaseField & {
	type: FieldType.Email;
};

export const parseEmailField = (field: FieldProto.AsObject): EmailInput => ({
	type: FieldType.Email,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	defaultValue: field.defaultValue,
	prefillFromField: field.prefillFromField
});
