import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { FieldType } from './field/FieldType';

export type PdfInput = Partial<BaseField> & {
	name: string;
	type: FieldType.Pdf;
	url: string;
};

export const parsePdfField = (field: FieldProto.AsObject): PdfInput => ({
	name: field.name,
	type: FieldType.Pdf,
	url: field.pdf!.url
});
