import { TrackerItemStatus } from '@agentero/components';
import { GetCarrierApplicationsStatusResponse } from '@agentero/grpc-clients/grpc/rater-fe';

import { InsuranceType, parseInsuranceTypeFromProto } from '../../opportunity';
import { Carrier, getCarrierFromProto } from '../../shared/Carrier';

export enum CarrierApplicationStatus {
	Available,
	NotAvailable,
	ReadyToQuote
}

export enum CarrierApplicationErrorStatus {
	Pending = 1,
	StateNotAvailable = 2,
	MissingCredentials = 3,
	MissingData = 4,
	FieldError = 5,
	StateDisabledTemporarily = 6,
	CoveragesNotStandard = 7,
	CarrierSpecificRequirementsNotMet = 8
}

export type FieldError = {
	destination: string;
	message: string;
};

export type CarrierApplicationError = {
	status: CarrierApplicationErrorStatus;
	message?: string;
};

export type CarrierApplication = {
	carrier: Carrier;
	status: CarrierApplicationStatus;
	error?: CarrierApplicationError;
	insuranceTypesList: InsuranceType[];
};

type ApplicationErrorStatusProto = Exclude<
	GetCarrierApplicationsStatusResponse.ApplicationErrorStatus,
	GetCarrierApplicationsStatusResponse.ApplicationErrorStatus.APPLICATION_ERROR_STATUS_UNSPECIFIED
>;

const applicationStatusErrorMapping: {
	[key in ApplicationErrorStatusProto]: CarrierApplicationErrorStatus;
} = {
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus.APPLICATION_ERROR_STATUS_PENDING]:
		CarrierApplicationErrorStatus.Pending,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_STATE_NOT_AVAILABLE]: CarrierApplicationErrorStatus.StateNotAvailable,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_MISSING_CREDENTIALS]:
		CarrierApplicationErrorStatus.MissingCredentials,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_MISSING_DATA]: CarrierApplicationErrorStatus.MissingData,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_FIELD_ERROR]: CarrierApplicationErrorStatus.FieldError,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_DISABLED_TEMPORARILY]:
		CarrierApplicationErrorStatus.StateDisabledTemporarily,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_COVERAGES_NOT_STANDARD]:
		CarrierApplicationErrorStatus.CoveragesNotStandard,
	[GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
		.APPLICATION_ERROR_STATUS_CARRIER_SPECIFIC_REQUIREMENTS_NOT_MET]:
		CarrierApplicationErrorStatus.CarrierSpecificRequirementsNotMet
};

const parseCarrierApplicationStatusError = (
	status: ApplicationErrorStatusProto
): CarrierApplicationErrorStatus => {
	return applicationStatusErrorMapping[status];
};

type ApplicationStatusProto = Exclude<
	GetCarrierApplicationsStatusResponse.ApplicationStatus,
	GetCarrierApplicationsStatusResponse.ApplicationStatus.APPLICATION_STATUS_UNSPECIFIED
>;

const applicationStatusEnumMap: {
	[key in ApplicationStatusProto]: CarrierApplicationStatus;
} = {
	[GetCarrierApplicationsStatusResponse.ApplicationStatus.APPLICATION_STATUS_AVAILABLE]:
		CarrierApplicationStatus.Available,
	[GetCarrierApplicationsStatusResponse.ApplicationStatus.APPLICATION_STATUS_NOT_AVAILABLE]:
		CarrierApplicationStatus.NotAvailable,
	[GetCarrierApplicationsStatusResponse.ApplicationStatus.APPLICATION_STATUS_READY_TO_QUOTE]:
		CarrierApplicationStatus.ReadyToQuote
};

const parseCarrierApplicationStatusEnum = (
	status: ApplicationStatusProto
): CarrierApplicationStatus => {
	return applicationStatusEnumMap[status];
};

const parseApplicationErrorStatus = (
	error?: GetCarrierApplicationsStatusResponse.ApplicationError.AsObject
): CarrierApplicationError | undefined => {
	return error
		? {
				status: parseCarrierApplicationStatusError(error.status as ApplicationErrorStatusProto),
				message: error.message
		  }
		: undefined;
};

export const parseCarrierApplication = (
	application: GetCarrierApplicationsStatusResponse.CarrierApplication.AsObject
): CarrierApplication => {
	return {
		carrier: getCarrierFromProto(application.carrier),
		status: parseCarrierApplicationStatusEnum(application.status as ApplicationStatusProto),
		error: parseApplicationErrorStatus(application.error),
		insuranceTypesList: application.insuranceTypesList.map(parseInsuranceTypeFromProto)
	};
};

const sortApplicationsByCarrierName = (applications: CarrierApplication[]): CarrierApplication[] =>
	applications.sort((a, b) => (a.carrier < b.carrier ? -1 : a.carrier > b.carrier ? 1 : 0));

export const getAvailableCarrierApplications = (
	applications: CarrierApplication[]
): CarrierApplication[] => {
	const availableAndReady = sortApplicationsByCarrierName(
		applications.filter(({ status }) => status === CarrierApplicationStatus.ReadyToQuote)
	);
	const availableAndNotReady = sortApplicationsByCarrierName(
		applications.filter(({ status }) => status === CarrierApplicationStatus.Available)
	);

	return [...availableAndReady, ...availableAndNotReady];
};

export const getIsEveryNotAvailableCarrierApplication = (
	applications: CarrierApplication[]
): boolean => applications.length > 0 && applications.every(({ error }) => error !== undefined);

export const getNotAvailableCarrierApplications = (
	applications: CarrierApplication[]
): CarrierApplication[] => {
	const notAvailable = applications.filter(
		({ status }) => status === CarrierApplicationStatus.NotAvailable
	);

	const missingData = sortApplicationsByCarrierName(
		notAvailable.filter(({ error }) => error?.status === CarrierApplicationErrorStatus.MissingData)
	);
	const fieldError = sortApplicationsByCarrierName(
		notAvailable.filter(({ error }) => error?.status === CarrierApplicationErrorStatus.FieldError)
	);
	const stateNotAvailable = sortApplicationsByCarrierName(
		notAvailable.filter(
			({ error }) => error?.status === CarrierApplicationErrorStatus.StateNotAvailable
		)
	);
	const missingCredentials = sortApplicationsByCarrierName(
		notAvailable.filter(
			({ error }) => error?.status === CarrierApplicationErrorStatus.MissingCredentials
		)
	);
	const pendingSetup = sortApplicationsByCarrierName(
		notAvailable.filter(({ error }) => error?.status === CarrierApplicationErrorStatus.Pending)
	);
	const statesDisabled = sortApplicationsByCarrierName(
		notAvailable.filter(
			({ error }) => error?.status === CarrierApplicationErrorStatus.StateDisabledTemporarily
		)
	);

	const coveragesNonStandardCarriers = sortApplicationsByCarrierName(
		notAvailable.filter(
			({ error }) => error?.status === CarrierApplicationErrorStatus.CoveragesNotStandard
		)
	);

	const carrierSpecificRequirements = sortApplicationsByCarrierName(
		notAvailable.filter(
			({ error }) =>
				error?.status === CarrierApplicationErrorStatus.CarrierSpecificRequirementsNotMet
		)
	);

	return [
		...missingData,
		...fieldError,
		...coveragesNonStandardCarriers,
		...stateNotAvailable,
		...statesDisabled,
		...missingCredentials,
		...pendingSetup,
		...carrierSpecificRequirements
	];
};

const trackerStatusFromCarrierApplicationStatusMapping = {
	[CarrierApplicationStatus.Available]: TrackerItemStatus.Default,
	[CarrierApplicationStatus.NotAvailable]: TrackerItemStatus.Disabled,
	[CarrierApplicationStatus.ReadyToQuote]: TrackerItemStatus.Ready
};

export const getTrackerStatus = (status: CarrierApplicationStatus) =>
	trackerStatusFromCarrierApplicationStatusMapping[status];
