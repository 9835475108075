import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';
import { numberWithCommas, stringToFloat } from '@agentero/utils';

import { BaseField } from './Field';
import { FieldDestination } from './field/FieldDestination';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';

export type AmountInput = BaseField & {
	type: FieldType.Amount;
	placeholder: string;
};

export const parseAmountField = (field: FieldProto.AsObject): AmountInput => ({
	type: FieldType.Amount,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	placeholder: field.amount?.placeholder as string,
	defaultValue: field.defaultValue,
	prefillFromField: field.prefillFromField
});

export const getAmountDestination = (
	field: AmountInput,
	value?: string,
	listsIndex: string[] = []
): FieldDestination => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	if (value === undefined || value === null) {
		return { [destination]: null };
	}

	return {
		[destination]: Math.floor(stringToFloat(value.toString())).toString()
	};
};

export const getAmountStateFromDestinations: FieldStateMapping<AmountInput> = (
	field,
	values,
	listsIndex
) => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const amountValue = valueToString(values[destination]);

	if (!amountValue) return {};

	return { [field.name]: numberWithCommas(amountValue) };
};
