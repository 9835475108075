import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { parseList } from '../../../shared/parseList';
import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { parseValidation } from './field/shared/Validation';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';
import { Option, parseOption } from './shared/Option';

export type ChoiceInput = BaseField & {
	type: FieldType.Choice;
	options: Option[];
	defaultValue?: string;
};

export const parseChoiceField = (field: FieldProto.AsObject): ChoiceInput => ({
	type: FieldType.Choice,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	options: parseList('Options', parseOption, field.choice?.optionsList),
	defaultValue: field.choice?.defaultValue ?? field.defaultValue,
	validation: parseValidation(field.validation),
	prefillFromField: field.prefillFromField
});

const hasOption = (options: Option[], option: string) =>
	options.some(({ value }) => value === option);
const isYesNo = (options: Option[]) =>
	options.length === 2 && hasOption(options, 'yes') && hasOption(options, 'no');

export const getChoiceStateFromDestinations: FieldStateMapping<ChoiceInput> = (
	field,
	values,
	listsIndex
) => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const dateValue = valueToString(values[destination]);

	const isYesNoInput = isYesNo(field.options);

	if (isYesNoInput) {
		if (dateValue == undefined) return { [field.name]: 'no' };
		if (['yes', 'no'].includes(dateValue)) return { [field.name]: dateValue };

		return { [field.name]: dateValue === 'true' ? 'yes' : 'no' };
	}

	if (!dateValue) return {}; //TODO: TEST

	return { [field.name]: dateValue };
};
