import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';
import { InvalidDataReceivedError } from '@agentero/service-errors';

import { When, parseWhen } from './When';

export type Validation = {
	when: When;
	message?: string;
};

export const parseValidation = (
	validation?: FieldProto.Message.AsObject | FieldProto.Validation.AsObject
): Validation | undefined => {
	if (!validation) return;

	if (!validation.when) {
		throw new InvalidDataReceivedError({
			messageError: `Property when is required in validation`,
			logMetadata: {}
		});
	}

	return {
		when: parseWhen(validation.when as FieldProto.When.AsObject),
		message: 'text' in validation ? validation.text : validation.message
	};
};
