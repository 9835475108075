import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';
import { parseNumber } from '@agentero/utils';

import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { parseValidation } from './field/shared/Validation';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';

export type NumberInput = Omit<BaseField, 'defaultValue'> & {
	type: FieldType.Number;
	numberType: NumberInputType;
	placeholder?: string;
	min?: number;
	max?: number;
	defaultValue?: number | string;
};

export enum NumberInputType {
	Integer,
	Decimal,
	Mask
}

export const parseNumberField = (field: FieldProto.AsObject): NumberInput => ({
	type: FieldType.Number,
	numberType: parseNumberInputType(field.number?.type),
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	min: field.number?.min !== undefined ? field.number?.min : undefined,
	max: field.number?.max ? field.number?.max : undefined,
	placeholder: field.number?.placeholder,
	defaultValue: parseInt(field.defaultValue ?? '0'),
	validation: parseValidation(field.validation),
	prefillFromField: field.prefillFromField
});

export const parseNumberInputType = (type = FieldProto.Number.NumberType.INTEGER) =>
	numberInputTypeMapping[type];

const numberInputTypeMapping: { [key in FieldProto.Number.NumberType]: NumberInputType } = {
	[FieldProto.Number.NumberType.INTEGER]: NumberInputType.Integer,
	[FieldProto.Number.NumberType.DECIMAL]: NumberInputType.Decimal,
	[FieldProto.Number.NumberType.MASK]: NumberInputType.Mask
};

export const getNumberStateFromDestinations: FieldStateMapping<NumberInput> = (
	field,
	values,
	listsIndex
) => {
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);
	const numberValue = valueToString(values[destination]);

	if (!numberValue) return {}; //TODO: TEST

	return { [field.name]: parseNumber(numberValue) };
};
