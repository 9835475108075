import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';
import { parseNumber } from '@agentero/utils';

import { BaseField } from './Field';
import { FieldDestination } from './field/FieldDestination';
import { parseFieldSize } from './field/FieldSize';
import { FieldStateMapping } from './field/FieldState';
import { FieldType } from './field/FieldType';
import { valueToString } from './field/FieldValue';
import { fieldDestinationIndexReplace } from './field/shared/fieldDestinationIndexReplace';
import { cleanNullsAndUndefinedDestinations } from './shared/cleanUndefinedDestinations';

export const YEAR_MONTH_INPUT_YEAR_PROP = 'year';
export const YEAR_MONTH_INPUT_MONTH_PROP = 'month';

export type YearMonthInput = Partial<BaseField> & {
	name: string;
	type: FieldType.YearMonth;
	destinationYear: string;
	destinationMonth: string;
	min?: number;
};

export type YearMonthState = {
	[YEAR_MONTH_INPUT_YEAR_PROP]: number;
	[YEAR_MONTH_INPUT_MONTH_PROP]: number;
};

export const parseYearMonthField = (field: FieldProto.AsObject): YearMonthInput => ({
	type: FieldType.YearMonth,
	name: field.name,
	label: field.label,
	destination: field.destination,
	destinationYear: field.yearmonth!.destinationYear,
	destinationMonth: field.yearmonth!.destinationMonth,
	min: field.yearmonth?.min ?? undefined,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	prefillFromField: field.prefillFromField
});

export const getMontYearDestination = (
	field: YearMonthInput,
	value?: YearMonthState,
	listsIndex: string[] = []
): FieldDestination => {
	const yearDestination = fieldDestinationIndexReplace(field.destinationYear, listsIndex);
	const monthDestination = fieldDestinationIndexReplace(field.destinationMonth, listsIndex);

	return {
		[yearDestination]: valueToString(value?.year),
		[monthDestination]: valueToString(value?.month)
	};
};

export const getYearMonthStateFromDestinations: FieldStateMapping<YearMonthInput> = (
	field,
	values,
	listsIndex
) => {
	const yearDestination = fieldDestinationIndexReplace(field.destinationYear, listsIndex);
	const monthDestination = fieldDestinationIndexReplace(field.destinationMonth, listsIndex);

	const yearValue = values[yearDestination];
	const monthValue = values[monthDestination];

	if (!yearValue && !monthValue) return {};

	return {
		[field.name]: cleanNullsAndUndefinedDestinations<YearMonthState>({
			[YEAR_MONTH_INPUT_YEAR_PROP]: parseNumber(yearValue),
			[YEAR_MONTH_INPUT_MONTH_PROP]: parseNumber(monthValue)
		})
	};
};

export const getYearMonthDestinationName = (field: YearMonthInput) => [
	field.destinationYear,
	field.destinationMonth
];
