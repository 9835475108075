import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { parseList } from '../../../shared/parseList';
import { BaseField } from './Field';
import { parseFieldSize } from './field/FieldSize';
import { FieldType } from './field/FieldType';
import { Option, parseOption } from './shared/Option';

export type SearchInput = BaseField & {
	type: FieldType.Search;
	options: Option[];
};

export const parseSearchField = (field: FieldProto.AsObject): SearchInput => ({
	type: FieldType.Search,
	name: field.name,
	label: field.label,
	destination: field.destination,
	size: parseFieldSize(field.size),
	required: field.required,
	description: field.description,
	options: parseList('Options', parseOption, field.search!.optionsList),
	defaultValue: field.defaultValue,
	prefillFromField: field.prefillFromField
});
