import {
	ListItem as ListItemStepProto,
	ListStep as ListStepProto
} from '@agentero/grpc-clients/grpc/shared/form';

import { deepMerge } from '../../../shared';
import { RiskState } from '../RaterSchema';
import { parseList, parseListArgs } from '../shared/parseList';
import { getDefaultValuesFromBasicStep } from './BasicStep';
import { ISummaryItem } from './ISummaryItem';
import { IStep, StepType } from './Step';
import {
	Section,
	SectionState,
	getSectionList,
	getSectionsDestinations,
	getSectionsStateFromDestinations,
	parseSection
} from './step/Section';
import { getFieldDestinationNames } from './step/section/Field';
import { FieldDestination } from './step/section/field/FieldDestination';

export type ListStep = IStep & {
	type: StepType.List;
	items: ListItemStep[];
};

export type ListItemStep = ISummaryItem & {
	name: string;
	minElements: number;
	maxElements: number;
};

export type ListStepState = {
	type: string;
	values: SectionState;
};

export const parseListStep = (step: ListStepProto.AsObject): ListStep => ({
	...step,
	items: parseList('items in ListStepProto.AsObject', parseListItems, step.itemsList),
	type: StepType.List
});

const parseListItems = (itemsStep: ListItemStepProto.AsObject): ListItemStep => ({
	name: itemsStep.name,
	sections: parseListArgs(
		'sectionsList in ListItemStepProto.AsObject',
		parseSection,
		getSectionList(itemsStep.sectionsList, itemsStep.formSectionsList)
	),
	maxElements: itemsStep.maxElements,
	minElements: itemsStep.minElements,
	summaryTitleText: itemsStep.summaryTitleText,
	summaryTitleFields: itemsStep.summaryTitleFieldsList,
	summarySubtitleFields: itemsStep.summarySubtitleFieldsList
});

export const getListStepDestinations = (
	step: ListStep,
	values: ListStepState[] = []
): FieldDestination => {
	const destinations = values.reduce((dictionary, value, index) => {
		const item = step.items.find(item => item.name === value.type);
		if (!item) return dictionary;

		return {
			...dictionary,
			...getSectionsDestinations(item.sections, value.values, [index.toString()])
		};
	}, {} as FieldDestination);

	return destinations;
};

export const getListStepStateFromDestinations = (
	step: ListStep,
	values: Record<string, string>
): ListStepState[] | undefined => {
	const formsState = step.items.reduce((acc, item) => {
		//This is because primary driver isn't something.[].something so we have to find if is a list item
		const hasSquareBrackets = item.sections.some(section =>
			section.fields.some(field =>
				getFieldDestinationNames(field).some(destination => destination.includes('[]'))
			)
		);

		if (!hasSquareBrackets) {
			const sectionsValues = getSectionsStateFromDestinations(item.sections, values);
			if (Object.keys(sectionsValues).length === 0) return acc;

			return [
				...acc,
				{
					type: item.name,
					values: sectionsValues
				}
			];
		}
		const sectionName = getSectionName(item.sections); //We assume all sections have same prefix in destination in every fields (change if it is not the case)
		const filteredValues = Object.keys(values).filter(key => key.startsWith(sectionName));
		const indexes = [...new Set(filteredValues?.map(key => key.split('.')[1]))];

		const items = indexes.map(index => {
			const sectionsValues = getSectionsStateFromDestinations(item.sections, values, [index]);
			return {
				type: item.name,
				values: sectionsValues
			};
		});

		return [...acc, ...items];
	}, [] as ListStepState[]);

	return formsState.length > 0 ? formsState : undefined;
};

const getSectionName = (sections: Section[]) =>
	mode(
		sections
			.map(section => section.fields)
			.flat()
			.map(field => getFieldDestinationNames(field)[0]?.split('.')[0] as string)
	);

const mode = (keys: string[]) =>
	keys.reduce(
		(acc, item) =>
			keys.filter(value => value === acc).length >= keys.filter(value => value === item).length
				? acc
				: item,
		''
	);

export const getDefaultValuesFromListStep = (
	step: ListStep,
	itemName: string,
	risk: RiskState
): SectionState => {
	const subsequentSelectedValues = getDefaultSubsequentValues(step, risk);

	const stepItem = step.items.find(item => item.name === itemName) as ListItemStep;
	const itemValues = getDefaultValuesFromBasicStep(stepItem, risk);

	return deepMerge(itemValues, subsequentSelectedValues);
};

export const getDefaultSubsequentValues = (step: ListStep, risk: RiskState) => {
	if (!step.items[0]) return {};

	const subSequentSection = step.items[0].sections.filter(
		({ repeatForSubsequent }) => repeatForSubsequent
	);
	const itemsValues = getListStepValues(step.id, risk);

	if (!itemsValues || !itemsValues[0]) return {};

	const { values } = itemsValues[0];

	return subSequentSection.reduce((state, section) => {
		return { ...state, [section.name]: values[section.name] };
	}, {} as SectionState);
};

export const getListStepValues = (stepKey: string, risk: RiskState) =>
	(risk[stepKey] as ListStepState[]) || [];
