import { Field } from '@agentero/grpc-clients/grpc/shared/form';

const ComparisonOperatorProto = Field.When.ComparisonOperator;

export enum ComparisonOperator {
	Unespecified,
	Equal,
	NotEqual,
	GreaterThan,
	GreaterThanOrEqual,
	LessThan,
	LessThanOrEqual,
	In,
	NotIn
}

const ComparisonMapping: { [key in Field.When.ComparisonOperator]: ComparisonOperator } = {
	[ComparisonOperatorProto.COMPARISON_OPERATOR_UNSPECIFIED]: ComparisonOperator.Unespecified,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_EQUAL]: ComparisonOperator.Equal,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_NOT_EQUAL]: ComparisonOperator.NotEqual,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_GREATER_THAN]: ComparisonOperator.GreaterThan,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_GREATER_THAN_OR_EQUAL]:
		ComparisonOperator.GreaterThanOrEqual,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_LESS_THAN]: ComparisonOperator.LessThan,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_LESS_THAN_OR_EQUAL]:
		ComparisonOperator.LessThanOrEqual,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_IN]: ComparisonOperator.In,
	[ComparisonOperatorProto.COMPARISON_OPERATOR_NOT_IN]: ComparisonOperator.NotIn
};

export const parseComparisonOperator = (
	operator: Field.When.ComparisonOperator
): ComparisonOperator => {
	return ComparisonMapping[operator];
};
