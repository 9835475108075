import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';
import { InvalidDataReceivedError } from '@agentero/service-errors';

import { When, parseWhen } from './shared/When';

export type DependsOn = {
	field: string;
	when: When;
};

export const parseDependsOn = (dependsOn: FieldProto.DependsOn.AsObject): DependsOn => {
	if (!dependsOn.when) {
		throw new InvalidDataReceivedError({
			messageError: `Property when is required in dependsOn`,
			logMetadata: {}
		});
	}

	return {
		...dependsOn,
		when: parseWhen(dependsOn.when as FieldProto.When.AsObject)
	};
};
