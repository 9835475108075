import { AmountInput, getAmountDestination } from '../AmountInput';
import { DateInput, getDateDestination } from '../DateInput';
import { Field } from '../Field';
import { VINInput, VINState, getVINDestination } from '../VINInput';
import { YearMonthInput, YearMonthState, getMontYearDestination } from '../YearMonthInput';
import { FieldType } from './FieldType';
import { FieldValue, valueToString } from './FieldValue';
import { fieldDestinationIndexReplace } from './shared/fieldDestinationIndexReplace';

export type FieldDestination = {
	[key: string]: string | undefined | null;
};

export const getDefaultDestination = (
	field: Field,
	value?: FieldValue,
	listsIndex?: string[]
): FieldDestination => {
	if (!field.destination)
		throw new Error(
			`Field of type ${field.type} can't be generated by getDefaultDestination, this field doesn't contain destination, use custom destination generator`
		);

	const resultValue = valueToString(value);
	const destination = fieldDestinationIndexReplace(field.destination, listsIndex);

	return resultValue ? { [destination]: resultValue } : { [destination]: null };
};

export const fieldDestinationMapping: {
	[key in FieldType]: (field: Field, value: FieldValue, listsIndex?: string[]) => FieldDestination;
} = {
	[FieldType.Text]: getDefaultDestination,
	[FieldType.Date]: (field, value, listsIndex) =>
		getDateDestination(field as DateInput, value as string, listsIndex),
	[FieldType.Amount]: (field, value, listsIndex) =>
		getAmountDestination(field as AmountInput, value as string, listsIndex),
	[FieldType.Dropdown]: getDefaultDestination,
	[FieldType.Choice]: getDefaultDestination,
	[FieldType.Email]: getDefaultDestination,
	[FieldType.Number]: getDefaultDestination,
	[FieldType.Search]: getDefaultDestination,
	[FieldType.VIN]: (field, value, listsIndex) =>
		getVINDestination(field as VINInput, value as VINState, listsIndex),
	[FieldType.YearMonth]: (field, value, listsIndex) =>
		getMontYearDestination(field as YearMonthInput, value as YearMonthState, listsIndex),
	[FieldType.Alert]: () => ({}),
	[FieldType.PhoneNumber]: getDefaultDestination,
	[FieldType.Checkbox]: getDefaultDestination,
	[FieldType.Signature]: getDefaultDestination,
	[FieldType.Pdf]: () => ({}),
	[FieldType.Video]: () => ({}),
	[FieldType.ScrollViewer]: () => ({}),
	[FieldType.TextArea]: getDefaultDestination,
	[FieldType.MultipleChoice]: getDefaultDestination
};
