import { InvalidDataReceivedError } from '@agentero/service-errors';

export const parseList = <T extends {}, R>(entity: string, parse: (item: T) => R, list?: T[]) => {
	if (!list) {
		throw new InvalidDataReceivedError({
			messageError: `Empty array for ${entity} property`,
			logMetadata: {}
		});
	}
	return list.map(parse);
};

export const parseListArgs = <T extends {}, R>(
	entity: string,
	parse: (item: T, listItems: T[]) => R,
	list?: T[]
) => {
	if (!list) {
		throw new InvalidDataReceivedError({
			messageError: `Empty array for ${entity} property`,
			logMetadata: {}
		});
	}
	return list.map(item => parse(item, list));
};
