import { Field as FieldProto } from '@agentero/grpc-clients/grpc/shared/form';

import { BaseField } from './Field';
import { FieldType } from './field/FieldType';

export type ScrollViewer = Partial<BaseField> & {
	name: string;
	type: FieldType.ScrollViewer;
	text: string;
};

export const parseScrollViewerField = (field: FieldProto.AsObject): ScrollViewer => ({
	name: field.name,
	type: FieldType.ScrollViewer,
	text: field.scrollViewer?.text ?? ''
});
