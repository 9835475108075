import { BasicStep as BasicStepProto } from '@agentero/grpc-clients/grpc/shared/form';

import { RiskState } from '../RaterSchema';
import { parseListArgs } from '../shared/parseList';
import { ISummaryItem } from './ISummaryItem';
import { IStep, StepType } from './Step';
import {
	Section,
	SectionState,
	SectionType,
	getSectionDefaultValues,
	getSectionList,
	getSectionsDestinations,
	getSectionsStateFromDestinations,
	parseSection
} from './step/Section';
import { FieldDestination } from './step/section/field/FieldDestination';

export type BasicStep = IStep &
	ISummaryItem & {
		type: StepType.Basic;
		gaAction?: string;
	};

export type BasicStepState = SectionState;

export const parseBasicStep = (step: BasicStepProto.AsObject): BasicStep => ({
	...step,
	sections: parseListArgs(
		'sectionsList in BasicStepProto.AsObject',
		parseSection,
		getSectionList(step.sectionsList, step.formSectionsList)
	),
	type: StepType.Basic,
	gaAction: step.gaAction,
	summaryTitleText: step.summaryTitleText,
	summaryTitleFields: step.summaryTitleFieldsList
});

export const getBasicStepDestinations = (
	step: BasicStep,
	values: BasicStepState
): FieldDestination => {
	return getSectionsDestinations(step.sections, values);
};

export const getBasicStepStateFromDestinations = (
	step: BasicStep,
	values: Record<string, string>
) => {
	const sectionsValues = getSectionsStateFromDestinations(step.sections, values);

	if (Object.keys(sectionsValues).length === 0) return;

	return sectionsValues;
};

export const getBasicStepDefaultValues = (
	step: BasicStep,
	risk: RiskState,
	prefill?: RiskState
): BasicStepState =>
	(risk[step.id] as BasicStepState) ??
	getDefaultValuesFromBasicStep(step, risk, prefill?.[step.id] as SectionState);

//Note: this is a fix when default values come from the schema
export const getDefaultValuesFromBasicStep = (
	step: { sections: Section[] },
	risk: RiskState,
	prefill?: SectionState
): BasicStepState => {
	if (!step) return {};

	const defaultValues: BasicStepState = step.sections?.reduce((result, section) => {
		if (section.type === SectionType.List) {
			const sectionListValues = [...new Array(section.minElements)].map(() =>
				getSectionDefaultValues({ section, risk, prefill })
			);

			return { ...result, [section.name]: sectionListValues };
		}

		const sectionValues = getSectionDefaultValues({ section, risk, prefill });

		return { ...result, [section.name]: sectionValues };
	}, {});

	return defaultValues;
};
