import { Option as OptionProto } from '@agentero/grpc-clients/grpc/shared/form';

export type Option = {
	label: string;
	value: string;
};

export const parseOption = (option: OptionProto.AsObject): Option => ({
	label: option.label,
	value: option.value
});
