import { GetAppointmentQuestionsSchemaResponse } from '@agentero/grpc-clients/grpc/appointment-fe';
import { GetRaterSchemaResponse } from '@agentero/grpc-clients/grpc/rater-fe';
import { RaterForm } from '@agentero/grpc-clients/grpc/shared/form';

import { Carrier, getCarrierFromProto } from '../../shared/Carrier';
import {
	RiskStepState,
	Step,
	getStepDestinations,
	getStepStateFromDestinations,
	parseStep
} from './raterSchema/Step';
import { FieldDestination } from './raterSchema/step/section/field/FieldDestination';
import { cleanNullsAndUndefinedDestinations } from './raterSchema/step/section/shared/cleanUndefinedDestinations';
import { parseList } from './shared/parseList';

export type RaterSchema = {
	steps: Step[];
	carriers: Carrier[];
};

export const isSchemaEmpty = (schema: RaterSchema) => schema && schema.steps.length === 0;

export const parseAppointmentSchema = (
	schema: GetAppointmentQuestionsSchemaResponse.AsObject
): RaterSchema => ({
	carriers: parseList('Carrier list', getCarrierFromProto, schema.appointmentForm?.carriersList),
	steps: parseList('Steps', parseStep, schema.appointmentForm?.stepsList)
});

export const parseRaterForm = (schema: RaterForm.AsObject): RaterSchema => ({
	carriers: parseList('Carrier list', getCarrierFromProto, schema.carriersList),
	steps: parseList('Steps', parseStep, schema?.stepsList)
});

export const parseRaterSchema = (schema: GetRaterSchemaResponse.AsObject): RaterSchema => ({
	carriers: parseList('Carrier list', getCarrierFromProto, schema.raterForm?.carriersList),

	steps: parseList('Steps', parseStep, schema.raterForm?.stepsList)
});

export type RiskState = {
	[key: string]: RiskStepState;
};

export const getRiskStateDestinations = (schema: RaterSchema, values: RiskState) => {
	const dictionary = schema.steps.reduce((dictionary, step) => {
		const fields = getStepDestinations(step, values);

		return { ...dictionary, ...fields };
	}, {} as FieldDestination);

	return Object.fromEntries(Object.entries(dictionary).filter(([, v]) => v != undefined));
};

export const getRiskStateDestinationsWithActiveStep = async (
	schema: RaterSchema,
	values: RiskState,
	activeStep: Step,
	hasMissingData?: (step: Step, values: RiskState) => boolean | Promise<boolean>
) => {
	const activeStepIndex = schema.steps.findIndex(value => value.id === activeStep.id);

	const stepValues = schema.steps
		.filter(step => {
			const stepIndex = schema.steps.findIndex(value => value.id === step.id);
			return activeStepIndex < 0 || stepIndex <= activeStepIndex;
		})
		.map(async stepValue => {
			const stepIndex = schema.steps.findIndex(value => value.id === stepValue.id);

			const step = schema.steps[stepIndex];

			const hasStepMissingData = hasMissingData ? await hasMissingData(step!, values) : false;

			return { step, stepIndex, hasStepMissingData };
		});

	const stepValuesResolve = await Promise.all(stepValues);

	const dictionary = stepValuesResolve.reduce(
		(dictionary, { step, stepIndex, hasStepMissingData }) => {
			if (!hasStepMissingData) {
				const fields = getStepDestinations(step, values);

				if (activeStepIndex !== stepIndex) {
					return { ...dictionary, ...fields };
				}

				return { ...dictionary, ...cleanNullsAndUndefinedDestinations(fields) };
			}

			return dictionary;
		},
		{} as FieldDestination
	);

	return Object.fromEntries(Object.entries(dictionary).filter(([, v]) => v !== undefined));
};

export const getRiskStateFromDestinations = (
	schema: RaterSchema,
	values: Record<string, string>
): Partial<RiskState> => {
	return schema.steps.reduce((acc, step) => {
		const stepState = getStepStateFromDestinations(step, values);
		if (stepState) return { ...acc, [step.id]: stepState };

		return acc;
	}, {});
};
